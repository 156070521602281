import React, { useContext, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Textarea,
    Input,
    useToast,
    Spinner,
  } from '@chakra-ui/react';
  import { CiDeliveryTruck } from "react-icons/ci";
import axios from 'axios';
import { orders_uri } from '../../../uri';
import { AuthContext } from '../../../context/auth';

const DeliveryModal = ({data, callback}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
    const {currentUser} = useContext(AuthContext);
    const [datas, setDatas] = useState({phone:"", address:"", explanation:"", token: currentUser, address_id:1});
    const [deliv, setDeliv] = useState(false);
    const [load, setLoad] = useState(false);
    const toast = useToast();

    const handlecall = () => {
      callback();
    }

    const Sub = async () => {
      if(datas.phone === "" || datas.address === "")
        return toast({title: 'Мэдээлэл дутуу байна ',status: 'warning',duration: 5000,isClosable: true,});

      setLoad(true);
      try{
        const res = await axios.post(orders_uri+"/delivery/web", datas);
        if(res.status === 200){
          onClose();
          handlecall();
          toast({title: 'Амжилттай бүртгүүллээ',status: 'success',duration: 5000,isClosable: true,});
          setDatas({...datas, phone:"", address:""});
          onOpen1();
        }

        setLoad(false);
      }catch(err){
        setLoad(false);
        console.log(err);
      }
    }

    const Get = async () => {
      setLoad(true);
      try{
        const res = await axios.get(orders_uri+"/delivery-check" + `?token=${currentUser}` );
        if(res.status === 208){
          setDeliv(true);
        }
        setLoad(false);
      }catch(err){
        console.log(err);
        setLoad(false);
      }
    }

    const callFunc = () => {
      onOpen();
      Get();
    }
    
  return (
    <>
      <Button className='mt-2' onClick={callFunc}>
        <CiDeliveryTruck size={24} className='mr-2'/>
        Хүргэлтэнд бүртгүүлэх
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} className="font-Roboto">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Хүргэлтэнд бүртгүүлэх</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              deliv ?
              <div>
                <h1>Та хүргэлтэнд бүртгүүлэсэн байна.</h1>
              </div>
              :
              <div>
                <h1 className='font-bold mb-4'>Таньд {data} бараа ирсэн байна.</h1>

                <h1 className='text-sm mb-2'>Нэмэлт утасны дугаар бичих</h1>
                <Input onChange={(e) => setDatas({...datas, phone:e.target.value})} className="" placeholder='99881122'/>

                <h1 className='text-sm mb-2 mt-4'>Гэрийн хаяг дэлгэрэнгүй бичих</h1>
                <Textarea onChange={(e) => setDatas({...datas, address:e.target.value})} className="" placeholder='Баянзүрх, 15-р хороо, 5-р байр, Хил хамгаалах ерөнхий газарын урд талын ногоон байр гадна нь ирээд залга гэх мэт ...'/>
                
                <h1 className='text-sm mb-2 mt-4'>Тэмдэглэл бичих </h1>
                <Textarea onChange={(e) => setDatas({...datas, explanation:e.target.value})} className="" placeholder='Та бэлэн бараа хадгалуулсан бол бэлэн барааны нэрээ бичнэ үү эсвэл бэлэн нэмж авах барааны нэрээ бичнэ үү'/>
              
              </div>
            }
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Болих
            </Button>
            {
              load ?
              <Button variant='ghost'><Spinner/></Button>
              :
              <Button variant='ghost' onClick={Sub}>Бүртгүүлэх</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen1} onClose={onClose1} className="font-Roboto">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <img src='../../images/sanamj.jpg'/>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose1}>
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeliveryModal
